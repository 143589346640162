.root {
  @apply flex items-center justify-between h-full px-2;
}
.input {
  border: none;
  border-bottom: 1px solid rgba(168, 164, 164, 0.5);
  margin-bottom: 2px;
  @apply flex-1 ml-2;
}

.input::placeholder {
  color: #a8a4a4;
}
