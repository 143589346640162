.root {
}
.shadow {
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 450ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  visibility: hidden;
  display: none;
}
.shadowActive {
  opacity: 1;
  visibility: visible;
  display: block;
}

.mobileFullWidth {
}
@media (max-width: theme("screens.sm")) {
  .mobileFullWidth {
    min-width: 100% !important;
  }
}

.translateLeftOut {
  transform: translateX(-100vw);
}
.translateRightOut {
  transform: translateX(100vw);
}
