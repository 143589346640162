.root {
  display: none;
  opacity: 0;
  position: fixed;
}

.top {
  top: 0;
  left: 0;
  width: 100%;
}

.bottom {
  top: var(--anchor-y);
  left: 0;
  width: 100%;
}

.open {
  display: block;
  opacity: 1;
  z-index: var(--z);
}

.content {
  background-color: theme("colors.white");
  max-height: var(--max-h);
  overflow-y: auto;
  overflow-x: hidden;
}
