.root {
  position: relative;
}

.count {
  position: absolute;
  top: -4px;
  right: -6px;
  font-size: 10px;
}
