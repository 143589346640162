.root {
  transition: transform 0.2s;
  transform: scale(0);
  @apply fixed bottom-4 right-4 z-1;
}
.visible {
  transform: scale(1);
}
.fab {
  font-size: 1rem;
}
