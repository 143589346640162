.drawerRoot {
}
.drawerRoot > div {
  flex: 1;
}
.highlighted {
  color: theme("colors.error") !important;
}
.underlined {
  text-decoration: underline !important;
}
.mainMenu {
  @apply flex flex-col justify-between h-full;
}
