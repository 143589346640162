.container {
  width: var(--size);
  height: var(--size);
}
.dot {
  background-color: var(--color);
  background-image: var(--background-img);
  background-position: center;
  background-size: cover;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickable {
  cursor: pointer;
}
