.root {
  width: 100%;
  position: relative;
}

.root::after {
  height: 0;
  content: "";
  display: block;
  padding-bottom: var(--ratio-sm);
}

.rootSmNoRect::after {
  padding-bottom: 0;
}

@screen sm {
  .root::after {
    padding-bottom: var(--ratio);
  }
  .rootSmNoRect::after {
    padding-bottom: var(--ratio);
  }
}

.rootMdNoRect::after {
  padding-bottom: 0;
}

@screen md {
  .root::after {
    padding-bottom: var(--ratio);
  }
  .rootMdNoRect::after {
    padding-bottom: var(--ratio);
  }
}

.rootLgNoRect::after {
  padding-bottom: 0;
}

@screen lg {
  .root::after {
    padding-bottom: var(--ratio);
  }
  .rootLgNoRect::after {
    padding-bottom: var(--ratio);
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.contentSmNoRect {
  position: relative;
}
@screen sm {
  .contentSmNoRect {
    position: absolute;
  }
}

.contentMdNoRect {
  position: relative;
}
@screen md {
  .contentMdNoRect {
    position: absolute;
  }
}

.contentLgNoRect {
  position: relative;
}
@screen lg {
  .contentLgNoRect {
    position: absolute;
  }
}
