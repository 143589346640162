.root {
  border-radius: 50%;
  @apply border-0 cursor-pointer;
}
.small {
  height: 36px;
  width: 36px;
}
.medium {
  height: 42px;
  width: 42px;
}
.large {
  height: 56px;
  width: 56px;
}
.primary {
  background-color: theme("colors.primary");
}
