.items {
  @apply flex flex-wrap pt-4 pb-4 sm:justify-center;
}

.link:hover {
  @apply no-underline;
}

.linkContainer {
  @apply my-1 mr-2 -ml-4 sm:ml-2;
}
