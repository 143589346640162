.control {
  cursor: pointer;
  font-size: 24px !important;
  min-width: 24px !important;
  min-height: 28px !important;
  color: theme("colors.black");
  margin-top: -4px !important;
  transition: font-size 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.controlShrinked {
  font-size: 24px;
}
.controlLeft {
  margin-right: 26px;
}
.controlRight {
  margin-left: 26px;
}
.wishlist {
  margin-top: 2px;
}
.search {
  margin-top: 2px;
}
.bottomControls {
  border-top: 1px solid theme("colors.lightestGray");
  border-bottom: 1px solid theme("colors.lightestGray");
  @apply flex items-center;
}
.menuControl {
  width: 42px;
  height: 42px;
  border-right: 1px solid theme("colors.lightestGray");
  @apply flex items-center justify-center;
}
.searchControl {
  height: 42px;
  @apply flex-1;
}
