.price {
  font-weight: 600;
}
.priceSmall {
  font-size: 1rem !important;
  line-height: 1rem !important;
}
.compareAtPrice {
  color: theme("colors.lightGray");
  text-decoration: line-through;
  font-size: 14px;
}
.compareAtPriceHorizontal {
  margin-right: 12px !important;
  line-height: 14px;
}
.newCompareAtPrice {
  color: theme("colors.error");
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 600;
}
.newCompareAtPriceHorizontal {
  margin-right: 12px !important;
  line-height: 14px;
}
.extraDiscountedPrice {
  color: theme("colors.primary");
  background-color: black;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
  @apply px-2 py-1;
}
.horizontalXs {
  @apply flex items-center;
}
.horizontal {
}
@screen sm {
  .horizontal {
    @apply flex items-center;
  }
}
@media (max-width: theme("screens.sm")) {
  .mobileCenter {
    justify-content: center;
    text-align: center !important;
  }
}
.verticalXs {
  text-align: left;
  height: 100%;
}

.verticalXs :global(.tw-typography:first-child) {
  margin-bottom: 4px;
  @apply sm:mb-0;
}
.vertical {
}
@screen sm {
  .vertical {
    text-align: right;
    height: 100%;
  }
}
.left {
  text-align: left;
  justify-content: flex-start;
}
