.root {
  @apply text-left !important;
}

.mobileCenter {
  @apply text-center sm:text-left !important;
}

.title {
  font-size: 1rem;
  font-family: Quattrocento;
  font-weight: normal;
  margin-bottom: 3px;
}

.ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  font-size: theme("fontSize.body1");
}

@media (max-width: theme("screens.sm")) {
  .subtitle {
    max-width: 60vw;
  }
}

.category {
  color: theme("colors.lightGray");
  font-size: theme("fontSize.body1");
  margin-top: 0.375rem;
}

.fillSubtitleHeight {
  min-height: 38px;
}
