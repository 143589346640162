.root {
  @apply flex flex-col justify-between sm:flex-row;
}
.info {
  flex: 2;
}
.form {
  flex: 3;
}
@screen sm {
  .form {
    padding-left: 5%;
  }
}
@media (max-width: theme("screens.sm")) {
  .form {
    @apply mt-6;
  }
}

.title {
  font-family: Quattrocento;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 1.45px;
  @apply flex items-center;
}
.emailInput {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid white;
  color: #ffffff !important;
}
.emailInputContainer {
  margin-left: 4px;
  height: 2rem !important;
}
.submitControl {
  @apply flex flex-col items-center justify-between pt-6 sm:flex-row;
}
.checkbox {
  font-size: 21px;
  @apply relative cursor-pointer;
}
.checkboxContainer {
  @apply flex items-center text-xs;
}
.checkboxContainer :global(.icon-checkbox-selected) {
  display: none;
}
.checkboxContainer :global(.icon-checkbox) {
  display: block;
}
.checkboxContainer input:checked + label :global(.icon-checkbox-selected) {
  display: block;
}
.checkboxContainer input:checked + label :global(.icon-checkbox) {
  display: none;
}
.button {
  color: #ffffff !important;
  border-color: white !important;
  height: 2.313rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  @apply flex items-center justify-center mt-4 sm:mt-0;
}
@media (max-width: theme("screens.sm")) {
  .button {
    @apply w-full mt-6;
  }
}
