.accordionSummaryRoot {
  margin-bottom: -1px;
  min-height: 3.5rem !important;
  @apply w-full pt-2 border-none cursor-pointer;
}
.accordionDetailsRoot {
  @apply px-2;
}
.icon {
  transition: transform 0.4s ease-in-out;
}
.iconMedium {
  font-size: 22px;
}
.iconSmall {
  font-size: 16px;
}
.iconExpanded {
  transform: rotate(180deg);
}
.textWhite {
  color: theme("colors.white");
}
.noGutters {
  @apply px-0;
}
.borderTop {
  border-top: 1px solid theme("colors.disabled");
  padding-bottom: 8px;
}
.borderBottom {
  border-bottom: 1px solid theme("colors.disabled");
}
