.root {
  background-color: var(--bg-color);
  font-size: 13px;
  font-weight: 500;
  line-height: var(--logo-height);
  z-index: 3;
  display: inline-block;
  height: var(--logo-height);
  margin-bottom: 10px;
  text-align: center;
  font-family: Quattrocento;
  color: var(--text-color);
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 4px;
}
