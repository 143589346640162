.listItem {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  font-weight: 500;
  padding-top: 8px;
  padding-bottom: 8px;
  @apply my-1 !important;
  @apply text-black;
}
.listItemInner {
  @apply flex items-center justify-between w-full text-base;
}
.listItemNarrow {
  @apply my-1;
}
.description {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: var(--bg-color);
  color: var(--text-color);
}
.iconContainer {
  font-size: 22px;
  min-width: 42px;
  line-height: 1px;
  display: flex;
  color: theme("colors.black") !important;
}
.link {
  font-weight: 500;
  text-align: left;
}
.link :global(.MuiTypography-root) {
  font-size: theme("fontSize.body1");
}
.arrow {
  min-width: 16px !important;
  color: theme("colors.black") !important;
}
.subitemsContainer {
  background-color: rgb(238, 241, 244);
  padding-top: 6px;
  padding-bottom: 6px;
}
.subitem {
  padding: 12px 4px 16px 26px;
}
