.descriptions {
  @apply pt-4 mb-4 text-center;
}

@screen sm {
  .descriptions {
    border-top: 1.5px solid theme("colors.lightestGray");
  }
}

.descriptionsLineXs {
  border-top: 1.5px solid theme("colors.lightestGray");
}

/* @media (max-width: theme("screens.sm")) {
  .descriptions {
    margin-top: 16px;
  }
} */

.previewControl {
  display: "block";
  padding-left: 0;
  margin-right: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}

.badges {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
}

.promoLogo {
  @apply mb-2;
}
