.input {
  font-size: theme("fontSize.base");
  font-weight: 300;
  color: black;
}
.input::placeholder {
  opacity: 0 !important;
}
.input:placeholder-shown + label {
  top: 50%;
  font-size: inherit;
}
.input:focus {
  border-color: theme("colors.primary");
}
.input:focus + label {
  top: 0%;
  font-size: theme("fontSize.xs");
  color: theme("colors.primary");
}
.inputFilled {
  padding-top: 16px;
}
.inputFilled + label {
  top: 20%;
  color: theme("colors.black");
}
.inputFilled:focus + label {
  top: 20%;
  color: theme("colors.black");
}
.inputError {
}
.inputError + label {
  color: theme("colors.error");
}
.endAdornment {
  position: absolute;
  right: 0;
  top: 6px;
}
.label {
  @apply absolute px-1 text-xs transition-all transform -translate-y-1/2 select-none cursor-text left-2 focus:outline-none;
}
.labelStandard {
  @apply top-0 bg-white;
}
