.bar {
  padding-top: 3px;
  padding-bottom: 3px;
  /* padding-top: var(--shrink-6px);
  padding-bottom: var(--shrink-6px); */
  transition: padding 0.3s;
  font-weight: 500;
}

@screen md {
  .bar {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.barItem {
  @apply flex items-center justify-center;
}

@media (max-width: theme("screens.md")) {
  .barItem {
    max-width: var(--max-width-mobile);
  }
}

@screen md {
  .barItem {
    max-width: var(--max-width-desktop);
  }
}

.slideText {
}
.slideText em {
  font-family: Quattrocento;
  font-style: normal;
}

.slideText :global(.tw-typography) {
  letter-spacing: var(--letter-spacing);
}

.slideTextWide {
}

.slideTextWide :global(.tw-typography) {
  letter-spacing: 1.71px;
}

@media (max-width: theme("screens.md")) {
  .slideText p {
    font-size: 12px;
  }
}

.button {
  padding: 2px 6px !important;
  font-size: 12px;
  line-height: 12px;
  @apply ml-3;
}

@screen md {
  .button {
    padding: 2px 16px !important;
    font-size: theme("fontSize.body2");
    line-height: theme("fontSize.body2");
    @apply ml-4;
  }
}
