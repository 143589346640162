.footer {
  padding-top: 3.5rem;
  background-color: black;
  color: #ffffff !important;
}
.footerTop {
  padding-left: min(7.813rem, 8.68vw);
  padding-right: min(8.625rem, 9.79vw);
  max-width: 1720px;
  margin: 0 auto;
  display: grid;
  grid-template:
    [row1-start] "logo newsletter" 1fr [row1-end]
    [row2-start] "logo box" auto [row2-end]
    [row3-start] "bottom bottom" auto [row3-end]
    / 23% auto;
}
.logo {
  grid-area: logo;
  width: 100%;
  padding-right: 30%;
}
.newsletter {
  grid-area: newsletter;
  gap: 3.75rem;
}
.box {
  grid-area: box;
  letter-spacing: 0;
  display: flex;
  justify-content: space-between;
  padding-bottom: 3.5rem;
  padding-top: 4rem;
}
.boxSections {
  flex: 1;
  @apply flex justify-between pr-2;
}
.boxSections :global(img) {
  display: none;
}
.socialSection {
  min-width: 18%;
}
.bottom {
  @apply flex items-center text-black bg-primaryLight;
  grid-area: bottom;
  height: 3.25rem;
}
.bottomInner {
  padding-left: min(7.813rem, 8.68vw);
  padding-right: min(8.625rem, 9.79vw);
  gap: 4px;
  max-width: 1720px;
  margin: 0 auto;
  @apply flex items-center justify-center w-full;
}

.boxTitle {
  font-family: CustomManrope;
  text-transform: uppercase;
  font-weight: 400;
}
