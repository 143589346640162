.button {
  padding: 5px !important;
  color: var(--icon-color);
  background-color: var(--bg-color);
  border-radius: 50%;
}

.button:hover {
  color: var(--icon-color);
  background-color: var(--bg-color);
}

.iconContainer {
  width: var(--size);
  height: var(--size);
  @apply flex items-center justify-center;
}
.iconContainer svg {
  font-size: 24px;
}
