.h100 > div,
.h100 > span {
  height: 100%;
  max-height: 100%;
}

.w100 > div,
.w100 > span {
  width: 100%;
  max-width: 100%;
}
