.root {
  padding-top: var(--py);
  padding-bottom: var(--py);
  padding-left: var(--px);
  padding-right: var(--px);
}
.closeRight {
  text-align: right;
}
.close {
  @apply mx-0 my-1;
}
.titleLeft {
  @apply text-left;
}
.titleCenter {
  @apply text-center;
}
