@media (min-width: 600px) {
  .spacing {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
  }

  .spacing > * {
    padding-left: 8px;
    padding-right: 8px;
  }
}
