.root {
  background-color: theme("colors.disabled");
  position: relative;
}
.container {
  min-height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4%;
  padding-right: 4%;
}
.input {
  background-color: transparent;
  border: none;
  text-align: center;
  min-width: 260px;
  font-size: 16px;
}
.searchIcon {
  font-size: 18px;
}
@screen sm {
  .searchIcon {
    font-size: 22px;
  }
  .container {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (max-width: theme("screens.sm")) {
  .searchIcon {
    font-size: 20px !important;
  }
  .searchIconContainer {
    position: absolute;
    left: 8px;
    padding-top: 4px;
  }
  .inputContainer {
    width: 100%;
  }
  .input {
    width: 100%;
  }
}
