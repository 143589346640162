.root {
  height: 100%;
  position: relative;
  overflow: auto;
}
.rightContent {
  position: absolute;
  top: 0;
  right: 0;
}
