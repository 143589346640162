.root {
  min-height: 60px;
  display: flex;
  align-items: center;
}
.arrow {
  margin-left: 16px;
  font-size: 22px;
  color: theme("colors.black");
}
.title {
  margin-left: 12px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-decoration: underline;
}
