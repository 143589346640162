.regular {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
