.rootFixed {
  padding-bottom: var(--header-height, 128px);
}

.headerRoot {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: theme("colors.white");
  z-index: 10;
}

.icon {
  font-size: 22px !important;
  color: theme("colors.black") !important;
}

.control {
  cursor: pointer;
  font-size: 28px;
  min-width: 36px;
  min-height: 36px;
  color: theme("colors.black");
  transition: font-size 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controlRight {
  @apply ml-4;
}

.controlLeft {
  @apply mr-4;
}

.header {
  padding-top: 3.2px;
  padding-bottom: 3.2px;
}

.headerContainer {
  /* padding-left: calc(8% - 5px) !important;
  padding-right: calc(8% - 5px) !important;
  @apply px-4; */
}

.headerContent {
  @apply flex items-center justify-start;
}
