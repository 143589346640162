.title {
  font-size: 1rem;
  font-weight: 500;
  @apply pb-4;
}
.results {
  padding-left: 0;
  padding-right: 0;
}
@screen sm {
  .results {
    padding-left: 4%;
    padding-right: 4%;
  }
}
.section {
  @apply mb-4 sm:text-center;
}
