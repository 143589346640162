.header {
  border-bottom: 1px solid #f2f2f2;
}

.inner {
  min-height: 38px;
  max-height: 38px;
  @apply flex items-center justify-between w-full;
}

.leftControls {
  flex-basis: 33%;
  display: flex;
  align-items: center;
}

.rightControls {
  flex-basis: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.control {
  cursor: pointer;
  font-size: 28px;
  min-width: 28px;
  min-height: 28px;
  color: #333;
  margin-top: -4px !important;
  transition: font-size 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  flex: 1;
  transition: padding 0.3s;
}

.logoContainerShrink {
  padding-left: 4px;
  padding-right: 4px;
}

.logo {
  max-height: 40px;
  @apply w-full h-auto align-middle;
}

.logoSeo {
  height: 40px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-image: var(--logo-url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logoSeo span {
  display: none;
}
