.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  flex: 1;
  position: relative;
}

@media (max-width: theme("screens.lg")) {
  .content {
    padding-top: var(--content-offset, 63px);
  }

  .headerBack {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--content-offset);
  }
}
