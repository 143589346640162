.root {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @apply px-4 py-2;
}
.login {
  @apply w-full mr-2;
}
.register {
  @apply w-full ml-2;
}
