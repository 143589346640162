.root {
  display: block;
  height: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: theme("colors.disabled");
  margin: 1em 0;
  padding: 0;
}
.noMargin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.fillContainer {
  @apply -mx-4;
}
