.searchControl {
  @apply cursor-pointer;
}
.label {
  text-transform: uppercase;
  font-weight: 400;
  @apply cursor-pointer select-none px-2 mx-4 !important;
}
.label:hover {
  opacity: 0.6;
}
.labelRight {
  font-weight: 500;
  @apply ml-6 mr-0 !important;
}
.controlHover {
  opacity: 0.9;
}
.panelAnchor {
  position: absolute;
  bottom: 0;
  left: 0;
}
.linkItem {
  padding-top: 9px;
  padding-bottom: 9px;
  z-index: 2;
  font-size: 0.875rem;
}
.linksBarPanelOpen {
  z-index: 3000;
}
.sideControlLeft {
  width: 18%;
  max-width: 18%;
  flex-basis: 18%;
}
.sideControlRight {
  width: 12%;
  max-width: 12%;
  flex-basis: 12%;
}
